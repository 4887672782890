<template>
    <div class="container">
        <div>
            <b-card 
            title="" sub-title="" class="shadow" id="fadein" style="background-color:whitesmoke"
            img-src="https://vip2.loli.io/2023/01/18/9GjeJNQDZXuroIc.jpg"
            img-alt="Image"
            img-top>
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>战斗，心无旁骛</h1>
                </b-card-title>
                <b-card-text>
                    作为玩家自发成立的组织，BFEAC致力于为战地带来良好的游戏环境。
                    <router-link to="/about" style="align-items: flex-end;"><i class="fa-solid fa-circle-info"></i>&nbsp;了解更多</router-link>
                </b-card-text>
                
            </b-card>
        </div>
        <br />
        <div>
            <b-card class="shadow" id="fadein" style="background-color:whitesmoke">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>公告</h1>
                </b-card-title>
                <b-card-text>
                    <p>2023年6月26日0时起对恶意使用bug实现异常射速与武器无后座射击进行处理，详情可见eac官方群公告。</p>
                    <p>如果需要举报，请登陆，登陆完成之后到“案件列表”下方进行举报即可。如无账号，请注册。</p>
                </b-card-text>
            </b-card>
        </div>
        <br />
        <div>
            <b-card title="" sub-title="" class="shadow" id="fadein">
                <b-card-title>
                    <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>踢出公示</h1>
                </b-card-title>
                <PageKickedLog />
            </b-card>
        </div>
    </div>
</template>

<script>
import PageKickedLog from "@/components/PageKickedLog";

export default {
    components: {
      PageKickedLog
    }
}


</script>